.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

* {
  box-sizing: border-box;
}

html,
body {
  overflow-x: hidden;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.add-client::after {
  content: "";
  position: absolute;
  width: 3px;
  height: 232px;
  background-color: #c9bebe;
  top: 100px;
  left: 58%;
}

.client-details table td {
  border: none !important;
  border-bottom: 1px solid gray !important;
}

.active {
  background-color: rgb(255, 123, 0);
}

.client-details {
  text-align: -webkit-center;
}

input:disabled {
  background-color: #d3d3d3;
}

.user_name {
  position: absolute;
  margin-top: -30px;
  margin-left: 998px;
}

.productTable td {
  border: 1px solid black;
  padding: 4px;
}

.add-client::after {
  content: "";
  position: absolute;
  width: 3px;
  height: 232px;
  background-color: #c9bebe;
  top: 100px;
  left: 58%;
}

.view_client {
  margin-left: 80px;
  margin-right: 90px;
}

.change_password {
  margin-top: 143px;
  margin-left: -79px;
  border: 1px solid #bdb1b1;
  padding: 26px;
  background-color: rgb(3 7 18 / 0.5);
}

input {
  outline: none;
}

.productTable input:focus {
  border: 1px solid #d3d3d3 !important;
  outline: none;
  outline: none;
}


.user_silider {
  height: auto;
  min-height: 100vh;
}

.suggestiion {
  height: 150px !important;
  padding: 10px !important;
}

.loder {
  position: absolute;
  margin-top: 150px;
  margin-left: 500px;
}

.excel_loder {
  position: absolute;
  margin-bottom: 170px;
  margin-left: 500px;
}

.productTable input[type="number"] {
  width: 70px;
  height: 35px;
}

.focus\:ring-indigo-600:focus {
  tw-ring-color: red !important;
}

.productTable .view_image img {
  margin-top: 16px !important;
}

.search_box {
  position: absolute;
  z-index: 1;
  /* background: white; */
  list-style: none;
  padding: 0px;
  /* margin-left: 25px; */
  margin-top: -17px;
  /* width: 53%; */
}

.search_box input {
  padding: 8px;
  margin-right: 8px;
  border-radius: 7px;
  width: 320px;
}

.search_box ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  border: 1px solid #ccc;
}

.search_box li {
  padding: 8px;
  cursor: pointer;
}

.search_box li:hover {
  background-color: #f2f2f2;
}

/* styles.css */
.pagination {
  display: flex;
  justify-content: center;
  /* Centers the pagination horizontally */
  align-items: center;
  /* Aligns buttons vertically */
  gap: 10px;
  /* Adds space between each button */
  margin-top: 20px;
}

.pagination-btn {
  padding: 8px 18px;
  /* Adds padding to create a larger clickable area */
  border-radius: 5px;
  /* Rounded corners */
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s, transform 0.3s;
  /* Smooth transitions */
  border: 1px solid #007bff;
  /* Default border color */
  background-color: #fff;
  /* Default background */
  color: #007bff;
  /* Default text color */
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.pagination-btn:hover {
  background-color: #007bff;
  /* Blue background on hover */
  color: #fff;
  /* White text on hover */
  transform: scale(1.05);
  /* Slight zoom effect */
}

.pagination-btn.disabled {
  background-color: #f0f0f0;
  /* Light gray for disabled state */
  color: #aaa;
  /* Gray text for disabled state */
  border-color: #ccc;
  /* Lighter border color for disabled */
  cursor: not-allowed;
  /* Indicates unclickable button */
}

.pagination-btn.active {
  background-color: #007bff;
  /* Blue background for the active page */
  color: white;
  /* White text for the active page */
  font-weight: bold;
  /* Bold text for the active page */
  border-color: #0056b3;
  /* Darker blue border for active state */
}

.pagination-btn:focus {
  outline: none;
  /* Removes the default outline on focus */
}

@media print {
  .page-break {
    display: block;
    height: 0;
    page-break-after: always;
    /* Ensure page breaks correctly */
  }

  /* Avoid breaking elements in the middle */
  .your-element-class {
    /* Change to your specific class */
    page-break-inside: avoid;
  }
}